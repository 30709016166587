.user-select {
  min-width: 240px;
}

@media (max-width: 576px) {
  .user-select {
    min-width: none;
    width: 100%;
    order: 2;
    margin-top: 15px;
  }
}
