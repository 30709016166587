@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #001529;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Profile Page CSS */
.profile_page_card {
  box-shadow: 0 13px 27px -5px rgb(50 50 93 / 25%), 0 8px 16px -8px rgb(0 0 0 / 30%), 0 -6px 16px -6px rgb(0 0 0 / 3%);
  border-radius: 4px;
}

.profile_save_btn {
  display: block;
  margin-left: auto;
}

.login_submit_btn {
  display: block;
  width: 100%;
}

.alert_error {
  margin-bottom: 8px;
}

.form_help_text {
  text-align: center;
}
