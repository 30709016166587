.copied-notification {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;
  text-align: center;
  padding: 5px;
}

.home-row-wrapper {
  display: flex;
  align-items: center;
}

.home-row-wrapper > *:not(:last-child) {
  margin-right: 15px;
}

.home-reflink {
  max-width: 20em;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.home-space {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1000px) {
  .home-space > .ant-space-item {
    width: 100%;
  }

  .home-reflink {
    max-width: 30em;
  }

  .home-row-wrapper {
    flex-direction: column;
  }

  .home-row-wrapper > *:not(:last-child) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .home-reflink {
    max-width: 100%;
  }
}
