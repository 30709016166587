.container {
  max-width: 1240px;
  padding: 0 20px;
  margin: auto;
  width: 100%;
}

@media (max-width: 576px) {
  .container {
    padding: 0 10px;
  }
}
