.sidebar {
  background: transparent;
  border-right: 1px solid #ccc;
  padding: 20px 0;
  max-width: 170px;
}

.sidebar-wrapper {
  padding: 0 20px;
}

.sidebar-item {
  width: 100%;
}

.sidebar-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 10px;
}

.sidebar-link._isActive {
  pointer-events: none;
}

.sidebar-link._isActive .sidebar-button {
  color: #40a9ff;
  border-color: #40a9ff;
}

.sidebar-icon {
  margin-right: 8px;
  flex-shrink: 0;
}

@media (max-width: 767px) {
  .sidebar {
    border-right: none;
    height: 80px;
    display: flex;
    padding: 0 10px 15px;
    max-width: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #ddd;
    background-color: #fff;
  }

  .sidebar-wrapper {
    padding: 0;
    margin: auto;
    width: 100%;
  }

  .sidebar-row {
    flex-wrap: nowrap;
  }

  .sidebar-item {
    width: auto;
    flex: 1;
  }

  .sidebar-button {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .sidebar-button {
    flex-direction: column;
    height: auto;
    padding: 6px 12px;
  }

  .sidebar-button span {
    font-size: 12px;
  }

  .sidebar-icon {
    margin-right: 0;
    margin-bottom: 4px;
  }
}
