@media (max-width: 767px) {
  .ant-table {
    font-size: 13px;
  }

  .ant-table .ant-table-cell {
    padding: 8px;
  }
}

@media (max-width: 767px) {
  tr {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 3px solid #f0f0f0;
  }
  th,
  td {
    flex-basis: 33.3%;
  }

  colgroup {
    display: none;
  }
}
