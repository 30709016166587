.header {
  padding: 0;
  height: 64px;
}

@media (max-width: 576px) {
  .header {
    height: 58px;
    line-height: 58px;
  }
}
