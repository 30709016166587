.leads-table {
}

.leads-table .row {
  display: flex;
  align-items: center;
}

.leads-table__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.2);
}

.leads-table__item:not(:last-child) {
  margin-bottom: 10px;
}

.leads-table__item-col {
  display: flex;
  flex-direction: column;
}

@media (max-width: 576px) {
  .leads-table__item {
    padding: 10px 8px;
  }
}
