.layout-container,
.main-layout {
  height: 100%;
  padding: 0;
}

.ant-layout.main-layout {
  flex-direction: row;
}

.main-layout-content {
  width: 100%;
  padding: 0 20px;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .ant-layout.main-layout {
    flex-direction: column-reverse;
  }

  .main-layout-content {
    overflow-y: auto;
    margin-bottom: 80px;
  }
}

@media (max-width: 576px) {
  .main-layout-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}
