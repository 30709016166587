.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-button {
  margin-left: 15px;
}

.header-logo {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .header-logo {
    width: 40px;
    height: 40px;
  }
}
