.auth-column {
  max-width: 540px;
  padding: 10px 30px;
  margin: auto;
  width: 100%;
}

@media (max-width: 576px) {
  .auth-column {
    padding: 20px 10px;
  }
}
